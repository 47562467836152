import {
  PartnerInfo,
  PartnerType,
  UserAccess,
  eUserRole
} from "@loadsure/core";
import { computed } from "@nuxtjs/composition-api";
import useAuthStore from "../stores/auth/auth.store";

export const useAccessHelper = () => {
  const authStore = useAuthStore();
  const { userAccess, partnerInfo } = authStore.getAuthState;
  const currentUserAccessInfo: UserAccess = UserAccess.fromObj(userAccess);
  const currentPartnerInfo: PartnerInfo = PartnerInfo.fromObj(partnerInfo);

  function isLoggedIn() {
    return computed(() => currentUserAccessInfo.isEmpty());
  }

  function hasNoPartnerAccess() {
    return computed(() => !!currentUserAccessInfo.hasNoPartnerAccess);
  }

  function isUserAdmin() {
    return computed(() => !!currentUserAccessInfo.hasRole(eUserRole.ADMIN));
  }

  function partnerType() {
    return computed(() => currentPartnerInfo.partnerType);
  }

  function isLoadsure() {
    return computed(() => !!currentUserAccessInfo.isLoadsure);
  }

  function isPortal() {
    return computed(() => !!currentUserAccessInfo.isPortal);
  }

  function isPartner() {
    return computed(() => !!currentUserAccessInfo.isPartner);
  }

  function isActiveUser() {
    return computed(() => !!currentUserAccessInfo.active);
  }

  function isAssuredPartner() {
    return computed(
      () => isPartner().value && PartnerType.isAssured(partnerType().value)
    );
  }

  function isRetailerPartner() {
    return computed(
      () => isPartner().value && PartnerType.isRetailer(partnerType().value)
    );
  }

  function isWholesalerPartner() {
    return computed(
      () => isPartner().value && PartnerType.isWholesaler(partnerType().value)
    );
  }

  function isDev() {
    return computed(
      () =>
        !!currentUserAccessInfo.hasRole(eUserRole.DEV) ||
        !!currentUserAccessInfo.hasRole(eUserRole.ADMIN)
    );
  }

  function canAccessIntegration() {
    const accessIntegration = computed(
      () => currentUserAccessInfo.isPartner 
    );
    return computed(() => accessIntegration.value === true);
  }

  function isLoadsureAdmin() {
    const loadsureAdmin = computed(
      () =>
        isLoadsure().value && !!currentUserAccessInfo.hasRole(eUserRole.ADMIN)
    );
    return computed(() => loadsureAdmin.value === true);
  }

  function isLoadsureUnderwriter() {
    return computed(
      () =>
        isLoadsure().value &&
        !!currentUserAccessInfo.hasRole(eUserRole.UNDERWRITER)
    );
  }

  function isPortalAdmin() {
    const portalAdmin = computed(
      () => isPortal().value && !!currentUserAccessInfo.hasRole(eUserRole.ADMIN)
    );
    return computed(() => portalAdmin.value === true);
  }

  function isPartnerAdmin() {
    const partnerAdmin = computed(
      () =>
        isPartner().value && !!currentUserAccessInfo.hasRole(eUserRole.ADMIN)
    );

    return computed(() => partnerAdmin.value === true);
  }

  function isPartnerOrPortalAdmin() {
    return computed(
      () => isPartnerAdmin().value === true || isPortalAdmin().value === true
    );
  }

  function isPortalUser() {
    return computed(
      () => isPortal().value && !!currentUserAccessInfo.hasRole(eUserRole.USER)
    );
  }

  function isPartnerUser() {
    return computed(
      () => isPartner().value && !!currentUserAccessInfo.hasRole(eUserRole.USER)
    );
  }

  function isAssuredPartnerAdmin() {
    return computed(
      () =>
        isAssuredPartner().value &&
        !!currentUserAccessInfo.hasRole(eUserRole.ADMIN)
    );
  }

  return {
    isLoggedIn,
    hasNoPartnerAccess,
    isUserAdmin,
    partnerType,
    isLoadsure,
    isPortal,
    isPartner,
    isActiveUser,
    isAssuredPartner,
    isRetailerPartner,
    isWholesalerPartner,
    isDev,
    canAccessIntegration,
    isLoadsureAdmin,
    isLoadsureUnderwriter,
    isPortalAdmin,
    isPartnerAdmin,
    isPartnerOrPortalAdmin,
    isPortalUser,
    isPartnerUser,
    isAssuredPartnerAdmin,
    currentUserAccessInfo
  };
};

export default useAccessHelper;
