
  /* eslint-disable no-restricted-globals */
  import {
    CHRISTMAS,
    isActiveSeason,
    useSiteConfigurationProvide
  } from "../use/useSiteConfiguration";
  import {
    Ref,
    defineComponent,
    onMounted,
    ref,
    useContext
  } from "@nuxtjs/composition-api";
  import { email, required } from "vuelidate/lib/validators";
  import { errors } from "../use/vuelidate";
  import {
    getAuth,
    isSignInWithEmailLink,
    onAuthStateChanged
  } from "firebase/auth";
  import { useCurrentUser, useCurrentUserProvide } from "../use/useFireAuth";
  import { useGlobalProvider } from "../use/useGlobal";
  import { useInternetTradingPolicyProvide } from "../use/useInternetTradingPolicy";
  import { useOverlayProvide } from "../use/useOverlay";
  import firebaseApp from "../plugins/firebase";
  import useAuthStore from "../stores/auth/auth.store";
  import useGTM from "../use/useGTM";

  export default defineComponent({
    setup() {
      const $fireAuth = getAuth(firebaseApp);
      const authStore = useAuthStore();
      const context = useContext();
      const { route, redirect, $config } = context;

      const { internetTradingPolicy } = useSiteConfigurationProvide(context);
      
      useOverlayProvide();
      const {
        login,
        loginInProgress,
        loggedEmail,
        authUser,
        userId,
        partnerId,
        logout,
        userPolicies
      } = useCurrentUserProvide(context);
      const GTM: Ref = ref();
      const isAnimationInProgress = ref(false);
      const { completeSignInWithEmail } = useCurrentUser(context);

      //   // initializing the internet trading policy context
      const { showITP } = useInternetTradingPolicyProvide(internetTradingPolicy, userPolicies);
      const {
        isGlobalAlertShow,
        globalAlertMessage,
        globalAlertType,
        hideGlobalAlert,
        globalAlertTimeout,
        globalBanner,
        showGlobalBanner,
        hideGlobalBanner,
        globalDialogShareAccess,
        showGlobalDialogShareAccess
      } = useGlobalProvider();

      const drawer: Ref<any> = ref(null);
      const show: Ref<
        "splash" | "signIn" | "signInAskForEmail" | "accessGranted"
      > = ref("splash");
      const accessDenied = ref("");

      const startPage: Ref<string> = ref("/dashboard");
      const signInUrl: Ref<string | undefined> = ref(undefined);

      function isDirectPurchase(path: string) {
        return path.startsWith("/insureLoad/directBuyV1");
      }

      function getStartPage(path: string, fullPath: string, href: string) {
        if (path === "/") {
          return "/dashboard";
        }
        if (isSignInWithEmailLink($fireAuth, href)) {
          if (path === "/") {
            return "/dashboard";
          }
          return path;
        }
        return fullPath;
      }

      async function onLogout() {
        show.value = "splash";
        authStore.resetAuthState();
        logout();
        window.localStorage.removeItem("startPage");
        startPage.value = "/dashboard";
        if (route.value.path !== "/") {
          redirect("/");
        }
        // logger.info("start page", startPage.value);
      }

      onMounted(async () => {
        if (isDirectPurchase(route.value.path)) {
          show.value = "accessGranted";
          return;
        }

        const isAllowedHost = ["loadsure.net", "localhost"].some((hostname) => window.location.hostname.includes(hostname));
        if (!isAllowedHost) {
          // logger.info("Need to redirect to", $config.baseUrl);
          window.location.replace(
            window.location
              .toString()
              .replace(window.location.origin, $config.baseUrl)
          );
        }

        startPage.value = getStartPage(
          route.value.path,
          route.value.fullPath,
          // eslint-disable-next-line no-restricted-globals
          location.href
        );

        if (isSignInWithEmailLink($fireAuth, location.href)) {
          const emailSignIn = window.localStorage.getItem("emailForSignIn");
          startPage.value =
            window.localStorage.getItem("startPage") ?? "/dashboard";
          // logger.info("Sign in with Email", email);
          if (emailSignIn) {
            await completeSignInWithEmail(emailSignIn, location.href);
          } else {
            signInUrl.value = location.href;
            show.value = "signInAskForEmail";
          }
        } else {
          window.localStorage.setItem("startPage", startPage.value);
        }

        onAuthStateChanged($fireAuth, async (newAuthUser) => {
          accessDenied.value = "";
          // logger.info("Auth State Changed", inspect(newAuthUser));
          if (newAuthUser) {
            const success = await login(newAuthUser);
            if (!success) {
              // await this.logout()
              accessDenied.value = newAuthUser.email || "unknown";
              show.value = "signIn";
            } else {
              if (route.value.fullPath !== startPage.value) {
                redirect(startPage.value);
              }
              const gtmLoginEvent = () => {
                const gtmObj = {
                  event: "login",
                  pageUrl: "/",
                  pageTitle: "Portal",
                  gtm: {
                    userId: userId.value,
                    partnerId: partnerId.value
                  }
                };
                GTM.value.fireEvent(gtmObj);
              };
              show.value = "accessGranted";
              gtmLoginEvent();
            }
          } else if (show.value !== "signInAskForEmail") {
            show.value = "signIn";
          }
        });
      });

      const year = new Date().getFullYear();
      const shortYear = year.toString().slice(-2);
      const handleAnimationStart = () => {
        isAnimationInProgress.value = true;
      };
      const handleAnimationEnd = () => {
        isAnimationInProgress.value = false;
      };

      return {
        accessDenied,
        loginInProgress,
        authUser,
        loggedEmail,
        drawer,
        signInUrl,
        onLogout,
        show,
        showITP,
        shortYear,
        GTM,
        GTMTimerInterval: $config.GTMTimerInterval,
        GTMTimerLimit: $config.GTMTimerLimit,
        handleAnimationStart,
        handleAnimationEnd,
        isAnimationInProgress,
        isActiveSeason,
        CHRISTMAS,
        isGlobalAlertShow,
        globalAlertMessage,
        globalAlertType,
        hideGlobalAlert,
        globalAlertTimeout,
        globalBanner,
        showGlobalBanner,
        hideGlobalBanner,
        globalDialogShareAccess,
        showGlobalDialogShareAccess
      };
    },
    validations: {
      globalDialogShareAccess: {
        email: {
          required,
          email
        }
      }
    },
    computed: {
      globalDialogShareAccessEmailError(this: any) {
        return errors(
          this.$v.globalDialogShareAccess.email,
          "globalDialogShareAccessEmail",
          {
            required: "This field is required"
          }
        );
      }
    },
    mounted() {
      this.GTM = useGTM(this, this.GTMTimerInterval, this.GTMTimerLimit);
    }
  });
